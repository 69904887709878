import * as actionTypes from './actionTypes'
import * as menuApi from '../../api/menuApi'
import { beginApiCall, apiCallError } from './apiStatusActions'
import { Dispatch } from 'redux'

export function loadMenuSuccess(menu: any) {
    return {
        type: actionTypes.LOAD_MENU_SUCCESS,
        menu
    }
}



export function loadMenuUsingRestaurantName(restaurant: string) {
    return function (dispatch: Dispatch) {
        dispatch(beginApiCall())
        return menuApi.getMenuByRestaurantName(restaurant)
            .then((res: any) => res.data)
            .then((menu: any) => {
                dispatch(loadMenuSuccess(menu))
            })
            .catch((err: Error) => {
                dispatch(apiCallError())
                throw err
            })
    }
}
