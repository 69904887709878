import React, { FC, useEffect, useState } from 'react';
import Header from './Header'
import CategorySelector from './CategorySelector'
import { connect } from 'react-redux';
import { IState, IOrdemDeServico } from '../../redux/reducers/initialState';
import __ from 'lodash'
import Menu from './Menu';
import MenuItem from './MenuItem';
import { RouteComponentProps } from 'react-router-dom';
import { loadMenuUsingRestaurantName } from '../../redux/actions/menuActions'
import { setRestaurante, resetPedido } from '../../redux/actions/serviceOrderActions'
import StymeLoading from '../StymeLoading';


interface IMenuProps extends RouteComponentProps<{ restaurant: string }> {
    menu: any,
    ordemDeServico: IOrdemDeServico,
    loadMenuUsingRestaurantName: Function,
    setRestaurante: Function,
    resetPedido: Function
}

const Catalog: FC<IMenuProps> = ({ menu, match, ordemDeServico, loadMenuUsingRestaurantName, setRestaurante, resetPedido }) => {
    
    
    
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!__.isEmpty(menu)) {
            setIsLoading(false)
            if (match.params.restaurant !== ordemDeServico.nomeDoRestaurante) {
                resetPedido(match.params.restaurant)
            }
            setRestaurante(match.params.restaurant)

        } else {
            setIsLoading(true)
            loadMenuUsingRestaurantName(match.params.restaurant)
                .catch((err: string) => {
                    window.location.assign('https://www.stymeapp.com/')
                })
        }
    }, [menu, match.params.restaurant, loadMenuUsingRestaurantName, resetPedido, setRestaurante, ordemDeServico.nomeDoRestaurante]);

    if (isLoading) {
        return <StymeLoading />
    }

    return <>
        <Header {...menu.companyData} />
        {!__.isEmpty(menu)
            && <div style={{ marginBottom: "100px" }}>
                <CategorySelector categories={
                    menu.categories.map((category: any) => (
                        {
                            id: category.categoryId,
                            name: category.description
                        }
                    ))
                } />
                {
                    // Map criado para criar novos componentes MenuTable para cada categoria
                    menu.categories.map((category: any, index: number) => {
                        // Filter usado para filtrar pratos que pertençam a uma determinada categoria. Após isso, map é feito para desestruturar dados dos pratos filtrados, de modo que só aquelas informações relevantes para o component Menu Table estejam presentes nos objetos do array. 
                        const dishesOfCategory = menu.dishes
                            .filter((dish: any) => {
                                return dish.collectionCategory[0].categoryId === category.categoryId
                            })
                            .map(({ productId, name, description, collectionProductPicture, price }: any) => {
                                return {
                                    id: productId,
                                    name,
                                    description,
                                    photoLink: collectionProductPicture[0] ? collectionProductPicture[0].path : "", price
                                }
                            })
                        return <Menu key={index} category={{ name: category.description, id: category.categoryId }}>
                            {dishesOfCategory.map((dish: any) => <MenuItem key={dish.id} restaurant={match.params.restaurant} {...dish} newlogo={menu.companyData.companyLogoPath} /*incluida passagem de parametro da empresa*/ />)}
                        </Menu>
                    })
                }
            </div>
        }
        
    </>
}

const mapDispatchToProps = {
    loadMenuUsingRestaurantName,
    setRestaurante,
    resetPedido
}

function mapStateToProps({ menu, ordemDeServico }: IState) {
    return {
        menu,
        ordemDeServico
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Catalog)