export const GENERATE_TOKEN_SUCCESS: string = "GENERATE_TOKEN_SUCCESS"
export const LOAD_MENU_SUCCESS: string = "LOAD_MENU_SUCCESS"
export const BEGIN_API_CALL: string = "BEGIN_API_CALL"
export const API_CALL_ERROR: string = "API_CALL_ERROR"

// Ações relacionadas a ordem de serviço
export const ADD_PRATO: string = "ADD_PRATO"
export const UPDATE_PRATO: string = "UPDATE_PRATO"
export const REMOVE_PRATO: string = "REMOVE_PRATO"
export const UPDATE_ENDERECO: string = "UPDATE_ENDERECO"
export const UPDATE_TIPO_DE_PAGAMENTO: string = "UPDATE_TIPO_DE_PAGAMENTO"
export const UPDATE_TIPO_DE_ENTREGA: string = "UPDATE_TIPO_DE_ENTREGA"
export const UPDATE_NOME_DO_CLIENTE: string = "UPDATE_NOME_DO_CLIENTE"
export const UPDATE_TELEFONE_DO_CLIENTE: string = "UPDATE_TELEFONE_DO_CLIENTE"
export const SET_RESTAURANTE: string = "SET_RESTAURANTE"
export const RESET_PEDIDO: string = "RESET_PEDIDO"
