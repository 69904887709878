import * as actionTypes from '../actions/actionTypes'
import initialState, { IOrdemDeServico } from './initialState'

const cachedData = JSON.parse(sessionStorage.getItem("ordemDeServico") as string)

const serviceOrderReducer: (state: IOrdemDeServico, action: any) => IOrdemDeServico = (state = cachedData || initialState.ordemDeServico, action) => {
    let newState = state
    let hasActionOcurred = false
    let dataNeedsToBeCached = true

    newState = { ...state }

    switch (action.type) {
        case actionTypes.SET_RESTAURANTE:
            newState = { ...newState, nomeDoRestaurante: action.restaurante }
            break;
        case actionTypes.RESET_PEDIDO:
            newState = { ...newState, pratos: [], valorTotal: 0, quantidadePratos: 0, nomeDoRestaurante: action.restaurante }
            break;
        case actionTypes.ADD_PRATO:
            newState = { ...newState, pratos: [...newState.pratos, action.prato] }
            hasActionOcurred = true
            break;
        case actionTypes.UPDATE_PRATO:
            let newPratos = [
                ...newState.pratos
            ].filter(prato => prato.idNoPedido !== action.idNoPedido)
            newPratos = [...newPratos, action.updatedData]
            newState = { ...newState, pratos: newPratos }
            hasActionOcurred = true
            break;
        case actionTypes.REMOVE_PRATO:
            newState = { ...newState, pratos: newState.pratos.filter(prato => prato.idNoPedido !== action.idPrato) }
            hasActionOcurred = true
            break;
        case actionTypes.UPDATE_ENDERECO:
            newState = { ...newState, endereco: { ...action.endereco } }
            break;
        case actionTypes.UPDATE_TIPO_DE_PAGAMENTO:
            newState = { ...newState, tipoDePagamento: action.tipoDePagamento }
            break;
        case actionTypes.UPDATE_TIPO_DE_ENTREGA:
            newState = { ...newState, tipoDeEntrega: action.tipoDeEntrega }
            break;
        case actionTypes.UPDATE_NOME_DO_CLIENTE:
            newState = { ...newState, nomeDoCliente: action.nomeDoCliente }
            break;
        case actionTypes.UPDATE_TELEFONE_DO_CLIENTE:
            newState = { ...newState, telefoneDoCliente: action.telefoneDoCliente }
            break;
        default:
            dataNeedsToBeCached = false
            break
    }
    if (hasActionOcurred) {
        const valorTotal = newState.pratos.reduce((prev, curr) => prev + (curr.valorTotal as number), 0)
        const quantidadePratos = newState.pratos.reduce((prev, curr) => prev + curr.quantidade, 0)
        newState = { ...newState, valorTotal, quantidadePratos }
    }

    if (dataNeedsToBeCached) sessionStorage.setItem("ordemDeServico", JSON.stringify(newState))

    return newState
}

export default serviceOrderReducer