import { handleResponse, handleError } from "./apiUtils";


export function getMenuByRestaurantName(restaurant: string) {
    const baseUrl = process.env["REACT_APP_STYME_API_URL"] + `/menudelivery/${restaurant}`
    //console.log(baseUrl)
    //console.log(process.env.NODE_ENV)
    var requestOptions = {
        method: 'GET',
    };

    return fetch(baseUrl, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}
