import React, { useState, useEffect } from 'react';
import './styles.css'
import { listenToScroll } from '../../../helpers/functions';
import $ from 'jquery'

export interface ICategory {
    name: string,
    id: number
}

export interface ICategorySelectorProps {
    categories: ICategory[],
}

const CategorySelector: React.FC<ICategorySelectorProps> = ({ categories }) => {

    const [scrollYPercent, setScrollYPercent] = useState<number>(window.pageYOffset)

    useEffect(() => {
        window.addEventListener('scroll', listenToScroll.bind(null, setScrollYPercent));
        return () => {
            window.removeEventListener('scroll', listenToScroll.bind(null, setScrollYPercent));
        };
    }, [])

    function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const elementId = `#${event.target.value}`
        const element = $(elementId);
        const offset: any = element.offset();
        $("body, html").animate({ scrollTop: offset.top - 250 }, 1000);
    }

    // return <div className={`categories-selector-container ${
    //     window.innerWidth > 700
    //         ? scrollYPercent > 1500 && "fixed"
    //         : scrollYPercent > 1500 && "fixed"
    //     }`}>
    return <div className={`categories-selector-container ${scrollYPercent > 1000 && "fixed"}`}>
        <select className="categories-selector" name="categoriesSelector" id="categoriesSelector" onChange={handleChange}>
            {categories.map(({ id, name }) =>
                <option key={id} value={`${id}`}>
                    {name}
                </option>
            )}
        </select>
    </div>

}

export default CategorySelector