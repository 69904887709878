import React from 'react';
import { ICategory } from '../CategorySelector';
import "./styles.css"
export interface IMenuTable {
    category: ICategory
}

const Menu: React.FC<IMenuTable> = ({ category, children }) =>
    <div className="menu-table">
        <h2 className="category-title" id={`${category.id}`}>
            {category.name}
        </h2>
        {children}
    </div>

export default Menu