import * as actionTypes from './actionTypes'
import { IPrato, IEndereco } from '../reducers/initialState'

export function addPrato(prato: IPrato) {
    return {
        type: actionTypes.ADD_PRATO,
        prato
    }
}

export function resetPedido(restaurante: string) {
    return {
        type: actionTypes.RESET_PEDIDO,
        restaurante
    }
}

export function setRestaurante(restaurante: string) {
    return {
        type: actionTypes.SET_RESTAURANTE,
        restaurante
    }
}

export function updateNomeDoCliente(nomeDoCliente: string) {
    return {
        type: actionTypes.UPDATE_NOME_DO_CLIENTE,
        nomeDoCliente
    }
}

export function updateTelefoneDoCliente(telefoneDoCliente: string) {
    return {
        type: actionTypes.UPDATE_TELEFONE_DO_CLIENTE,
        telefoneDoCliente
    }
}

export function updatePrato(idNoPedido: number, updatedData: IPrato) {
    return {
        type: actionTypes.UPDATE_PRATO,
        idNoPedido,
        updatedData
    }
}

export function removePrato(idPrato: number) {
    return {
        type: actionTypes.REMOVE_PRATO,
        idPrato
    }
}

export function updateEndereco(endereco: IEndereco) {
    return {
        type: actionTypes.UPDATE_ENDERECO,
        endereco
    }
}

export function updateTipoDePagamento(tipoDePagamento: "Crédito" | "Débito") {
    return {
        type: actionTypes.UPDATE_TIPO_DE_PAGAMENTO,
        tipoDePagamento
    }
}

export function updateTipoDeEntrega(tipoDeEntrega: "Local" | "Delivery") {
    return {
        type: actionTypes.UPDATE_TIPO_DE_ENTREGA,
        tipoDeEntrega
    }
}