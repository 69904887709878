import React, { useState, useEffect } from 'react';
import './styles.css'
//import logo from '../../../assets/dish-fallback.svg'
import { listenToScroll } from '../../../helpers/functions';
import { ICompanyData } from '../../../redux/reducers/initialState';
import stymeOnLogo from '../../../assets/STYME_ID_Logo_RGB_Horizontal_Positivo.png'
import defaultBanner from "../../../assets/ic_chose_table_background.png"


const Header: React.FC<ICompanyData> = (companyData) => {

    const [scrollYPercent, setScrollYPercent] = useState<number>(window.pageYOffset)

    useEffect(() => {
        window.addEventListener('scroll', listenToScroll.bind(null, setScrollYPercent));
        return () => {
            window.removeEventListener('scroll', listenToScroll.bind(null, setScrollYPercent));
        };
    }, [])

    return <header>
        <div className="header-head" >
            <div className="styme-on-logo">
                <img src={stymeOnLogo} alt="styme-on-logo" />
            </div>
        </div>
        <div className={`header-body ${scrollYPercent > 1000 && "minimized"}`}>
            {
                scrollYPercent > 1000
                    ? (companyData.companyName || "Restaurant Name")
                    :
                    <div className="header-banner" style={{ backgroundImage: `url(${companyData.companyAdvertisementPath || defaultBanner})` }}>
                        <img src={companyData.companyLogoPath} />
                    </div>
            }
        </div>
        <div className="header-footer">
            <div className="restaurant-data">
                <h1 className="restaurant-title">
                    {companyData.companyName}
                </h1>
            </div>
        </div>
    </header>
}


//
export default Header