import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import "./style.css"

interface IRedirectButtonProps {
    direction: "left" | "right"
    route: string
    message?: string
}

const RedirectButton: FC<IRedirectButtonProps> = ({ direction, route, message }) =>
    <Link to={route}>
        <button className="redirect-button">
            <FontAwesomeIcon icon={direction === "right" ? "arrow-right" : "arrow-left"} />
            {message}
        </button>
    </Link>

export default RedirectButton