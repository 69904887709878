import * as actionTypes from '../actions/actionTypes'
import initialState from './initialState'

export default function menuReducer(state = initialState.menu, action: any) {
    switch (action.type) {
        case actionTypes.LOAD_MENU_SUCCESS:
            let dishes = action.menu.aLaCarteData.products.map((product: any) => ({ ...product, ...action.menu.products.find((p: any) => p.productId === product.productId) }))
            dishes = dishes.map((dish: any) => {
                let newDish = {
                    ...dish,
                    collectionQuestionRequired: dish.collectionQuestionRequired && action.menu.questions.filter((question: any) => {
                        return dish.collectionQuestionRequired.includes(question.questionId)
                    }),
                    collectionQuestionAdditional: dish.collectionQuestionRequired && action.menu.questions.filter((question: any) => {
                        return dish.collectionQuestionAdditional.includes(question.questionId)
                    })
                }
                return newDish
            })
            const menu = {
                dishes,
                companyData: {
                    deliveryTime: action.menu.deliveryTime,
                    workingDayAndTime: action.menu.workingDayAndTime,
                    address: action.menu.address,
                    companyLogoPath: action.menu.companyLogoPath,
                    companyName: action.menu.companyName,
                    online: action.menu.online,
                    companyMobileNumber: action.menu.companyMobileNumber,
                    companyAdvertisementPath: action.menu.collectionCompanyAdvertisement && action.menu.collectionCompanyAdvertisement[0].path
                },
                categories: action.menu.aLaCarteData.categories
            }
            return menu
        default:
            return state
    }
}