import React, { FC } from 'react';

import Menu from './catalog'
import Detail from './catalog/Detail'

import { ToastContainer } from 'react-toastify'
import { Switch, Route } from 'react-router-dom'
import RedirectToStyme from './RedirectToStyme';
import StymeLoading from './StymeLoading';
import "react-toastify/dist/ReactToastify.css"

function RedirectWithLoading() {
  return <>
    <StymeLoading />
    <RedirectToStyme />
  </>
}

const App: FC = () => {
  return <>
    <Switch>
      <Route exact path="/:restaurant" component={Menu} />
      <Route exact path="/" render={RedirectWithLoading} />
      <Route path="/:restaurant/cardapio" component={Menu} />
      <Route exact path="/:restaurant/prato/:idDish" component={Detail} />
      <Route path="/" render={RedirectWithLoading} />
    </Switch>
    <ToastContainer autoClose={3000} hideProgressBar />
  </>
}

//<Route exact path="/" render={RedirectWithLoading} />
//<Route exact path="/:restaurant" component={HomeContainer} />

//<Route path="/:restaurant/pedido" component={Order} />
//<Route path="/:restaurant/prato/:idDish/edit/:idNoPedido" component={Detail} />

//https://www.pexels.com/pt-br/procurar/food/ -> site de comida
export default App;
