import React, { FC } from 'react';
import logo from "../../assets/STYME_ID_Logo_RGB_Secundaria_Azul.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./styles.css"

const StymeLoading: FC = () => <div className="styme-loading">
    <div className="logo-container">
        <img src={logo} alt="styme-logo" />
    </div>
    <FontAwesomeIcon icon="spinner" spin size="2x" />
</div>

export default StymeLoading