import { combineReducers } from 'redux'
import menu from './menuReducer'
import token from './tokenReducer'
import apiCallsInProgress from './apiStatusReducer'
import ordemDeServico from './serviceOrderReducer'

const rootReducer = combineReducers({
    token,
    menu,
    ordemDeServico,
    apiCallsInProgress
})

export default rootReducer