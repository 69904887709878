import React, { FC } from 'react';
import RedirectButton from '../RedirectButton';
import MenuItem from '../MenuItem';
import { IPrato } from '../../../redux/reducers/initialState';
import "./styles.css"
import { RouteComponentProps, withRouter } from 'react-router-dom';

export interface DetailPageProps extends RouteComponentProps {
    prato: IPrato,
    setPrato: Function,
    addPrato: Function,
    updatePrato: Function,
    restaurant: string
}

export const calculaValorTotalPrato: (prato: IPrato) => number = ({ valor, respostas, quantidade }) => {
    const valorTotalPerguntas = respostas.reduce((prev, curr) => {
        return prev + curr.price
    }, 0)

    return quantidade * (valor + valorTotalPerguntas)
}

const DetailItem: FC<DetailPageProps> = ({ history, prato, restaurant, setPrato, addPrato, updatePrato }) => {

    return <div className="detail-screen">
        <div className="detail-header">
            <RedirectButton direction="left" route={`/${restaurant}/cardapio`} />
            <h1>Detalhe do produto</h1>
        </div>
        {console.log(prato)}
        <MenuItem id={prato.id} description={prato.descricao} name={prato.nome} photoLink={prato.photoLink} price={prato.valor} restaurant={restaurant} format="imageFull" />
    </div>
}

export default withRouter(DetailItem)