export interface IToken {
    access_token: string,
    expires_in: number,
    token_type: string,
    scope: string
}

export interface IResposta {
    perguntaId: number,
    id: number,
    nome: string,
    price: number
}

// export interface IQuestaoRespondida {
//     id: number,
//     questao: string,
//     respostas: IResposta[]
// }

export interface IPrato {
    idNoPedido: number,
    id: number,
    nome: string,
    descricao: string,
    valor: number,
    valorTotal: number,
    respostas: IResposta[]
    questoesOpcionais: any[],
    questoesObrigatorias: any[],
    quantidade: number,
    photoLink: string,
    observacao: string
}

export interface IEndereco {
    bairro: string,
    cep: string,
    complemento: string,
    localidade: string,
    logradouro: string
    uf: string
    numero: string
}


export interface IState {
    token: IToken,
    menu: any,
    apiCallsInProgress: number,
    ordemDeServico: IOrdemDeServico
}

export interface ICompanyData {
    deliveryTime: string,
    workingDayAndTime: string,
    address: string,
    companyLogoPath: string,
    online: boolean,
    companyName: string,
    companyMobileNumber: string,
    companyAdvertisementPath: string,
}

export interface IOrdemDeServico {
    nomeDoRestaurante: string,
    nomeDoCliente: string,
    telefoneDoCliente: string,
    pratos: IPrato[],
    valorTotal: number,
    endereco: IEndereco | null,
    tipoDePagamento: "Débito" | "Crédito" | "",
    tipoDeEntrega: "Local" | "Delivery" | "",
    quantidadePratos: number
}

const initialState: IState = {
    apiCallsInProgress: 0,
    menu: {},
    ordemDeServico: {
        nomeDoRestaurante: "",
        nomeDoCliente: "",
        telefoneDoCliente: "",
        pratos: [],
        valorTotal: 0,
        quantidadePratos: 0,
        endereco: {
            bairro: "",
            cep: "",
            complemento: "",
            localidade: "",
            logradouro: "",
            uf: "",
            numero: ""
        },
        tipoDePagamento: '',
        tipoDeEntrega: ''
    },
    token: {} as IToken
}

export default initialState