import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom'
import configureStore from './redux/configureStore'
import { Provider as ReduxProvider } from 'react-redux'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGripVertical, faArrowRight, faArrowLeft, faCircle, faClock, faMapMarker, faMotorcycle, faSpinner, faShoppingCart } from '@fortawesome/free-solid-svg-icons'

library.add(faGripVertical, faArrowRight, faArrowLeft, faCircle, faClock, faMapMarker, faMotorcycle, faSpinner, faShoppingCart)

const store = configureStore()

//alert(process.env.REACT_APP_API_URL + '\n' + process.env.REACT_APP_TOKEN_URL)

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <Router>
        <App />
      </Router>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
