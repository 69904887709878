import React, { FC } from 'react';
import './styles.css'
import { Link } from 'react-router-dom'
import fallback from '../../../assets/newDish.png'


export interface IDishCardProps {
    id: number,
    newlogo?: string,
    name: string,
    description: string,
    photoLink: string,
    price: number,
    restaurant: string,
    format?: "imageFull" | "imageMinified" | null

}
const parse = require('html-react-parser');


const MenuItem: FC<IDishCardProps> = (
    {
        id,
        name,
        description,
        photoLink,
        price,
        restaurant,
        format,
        newlogo
    }) => {

    const DishCardRender: any = <div className={`dish-card ${format === "imageFull" && "image-full"}`}>
        <div className="dish-data">
            <strong className="dish-title">
                {name}
            </strong>
            <p className="dish-description">
                {description ? parse("" + description + "") : ""}
            </p>
            <p className="dish-price">
                {price?.toLocaleString("pt-BR", { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}
            </p>

        </div>
        <div className="dish-thumbnail">
            <img src={photoLink || newlogo /*incluido o logo do restaurante caso falte*/ || fallback} alt={`${name}-thumbnail`} />
        </div>
    </div>

    if (format === "imageFull") {
        return DishCardRender
    }
    return <Link to={`/${restaurant}/prato/${id}`}>
        {DishCardRender}
    </Link>
}

export default MenuItem