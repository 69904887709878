import React, { FC, useState, useEffect } from 'react';
import { IState, IOrdemDeServico, IPrato } from '../../../redux/reducers/initialState';
import { addPrato, updatePrato, setRestaurante, resetPedido } from '../../../redux/actions/serviceOrderActions'
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import DetailItem from '../DetailItem';
import __ from 'lodash'
import { loadMenuUsingRestaurantName } from '../../../redux/actions/menuActions';
import StymeLoading from '../../StymeLoading';


interface IDetailMatchParams {
    restaurant: string,
    idDish: string,
    idNoPedido?: string
}
interface IDetailProps extends RouteComponentProps<IDetailMatchParams> {
    addPrato: Function,
    updatePrato: Function,
    ordemDeServico: IOrdemDeServico,
    menu: any
    loadMenuUsingRestaurantName: Function,
    setRestaurante: Function,
    resetPedido: Function
}

const getDishFromMenu: (menu: any, dishId: number, pratosNoPedido: IPrato[], history: any, restaurant: string) => IPrato | void = (menu, dishId, pratosNoPedido, history, restaurant) => {
    const pratoNoPedido = pratosNoPedido.find(prato => prato.idNoPedido === dishId)
    const dishFound = menu.dishes.find((dish: any) => dish.productId === dishId)

    if (__.isEmpty(pratoNoPedido) && __.isEmpty(dishFound)) {
        history.push(`/${restaurant}/cardapio`)
        return
    }

    if (!__.isEmpty(pratoNoPedido)) return pratoNoPedido

    return {
        idNoPedido: 0,
        descricao: dishFound.descriptionHtml,
        nome: dishFound.name,
        id: dishFound.productId,
        quantidade: 1,
        questoesObrigatorias: dishFound.collectionQuestionRequired,
        questoesOpcionais: dishFound.collectionQuestionAdditional,
        valor: dishFound.price,
        valorTotal: dishFound.price,
        respostas: [],
        photoLink: dishFound.collectionProductPicture[0] && dishFound.collectionProductPicture[0].path,
        observacao: ""
    }
}

const Detail: FC<IDetailProps> = ({ match, menu, addPrato, updatePrato, ordemDeServico, history, loadMenuUsingRestaurantName, setRestaurante, resetPedido }) => {
    const [prato, setPrato] = useState<IPrato>({} as IPrato)
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!__.isEmpty(menu)) {
            setPrato(getDishFromMenu(menu, parseInt(match.params.idNoPedido ? match.params.idNoPedido : match.params.idDish), ordemDeServico.pratos, history, match.params.restaurant) as IPrato)
        }
    }, [menu, match.params.idNoPedido, match.params.idDish, ordemDeServico.pratos, history, match.params.restaurant])

    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (!__.isEmpty(menu)) {
            setIsLoading(false)
            if (match.params.restaurant !== ordemDeServico.nomeDoRestaurante) {
                resetPedido(match.params.restaurant)
            }
            setRestaurante(match.params.restaurant)

        } else {
            setIsLoading(true)
            loadMenuUsingRestaurantName(match.params.restaurant)
                .catch((err: string) => {
                    window.location.assign('https://www.stymeapp.com/')
                })
        }
    }, [menu, match.params.restaurant, loadMenuUsingRestaurantName, resetPedido, setRestaurante, ordemDeServico.nomeDoRestaurante]);


    if (isLoading) {
        return <StymeLoading />
    }

    return <DetailItem updatePrato={updatePrato} prato={prato} restaurant={match.params.restaurant} setPrato={setPrato} addPrato={addPrato} />
}

function mapStateToProps({ ordemDeServico, menu }: IState) {
    return {
        ordemDeServico,
        menu
    }
}

const mapDispatchToProps = {
    addPrato,
    updatePrato,
    loadMenuUsingRestaurantName,
    setRestaurante,
    resetPedido
}

export default connect(mapStateToProps, mapDispatchToProps)(Detail)