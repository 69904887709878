import React, { FC, useEffect } from 'react';

const RedirectToStyme: FC = () => {
    useEffect(() => {
        window.location.assign('https://www.stymeapp.com/');
    }, []);

    return <></>
}

export default RedirectToStyme